import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { authApi } from '@breve/services/auth.services'
import type { AuthState, UserProfile } from '@breve/store/redux/authSlice'
import {
  selectCurrentUser,
  selectIsLoggingOut,
  setProfile,
} from '@breve/store/redux/authSlice'
import { store } from '@breve/store/redux/store'

export const useAuth = (): AuthState => {
  let user: UserProfile | null = null
  const currentUser = useSelector(selectCurrentUser)
  const hasLoggedOut = useSelector(selectIsLoggingOut)
  const accessToken = localStorage.getItem('accessToken')
  const refreshToken = localStorage.getItem('refreshToken')

  if (!hasLoggedOut) {
    user = currentUser
  }
  if (!user) {
    if (accessToken && refreshToken) {
      if (!hasLoggedOut) {
        Promise.resolve(
          store
            .dispatch(authApi.endpoints.fetchUser.initiate())
            .then(({ data: userProfile }) => {
              if (!userProfile) return

              store.dispatch(setProfile({ ...userProfile }))
            })
        ).catch(null)
      }
    }
  }

  return useMemo(() => ({ user, hasLoggedOut }), [user, hasLoggedOut])
}
